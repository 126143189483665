<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="订单信息">
        <!-- <icon-button @click.native="goBack" icon="return" slot="extra" /> -->
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 订单编号</template>
            <span>{{ orderDetatilList.orderCode }}</span
            ><span class="span-margin" @click="checkPicture"
          ><el-link :underline="false" type="primary">
                查看图片</el-link
          ></span
          >
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单状态</template>
            {{ orderStatus[orderDetatilList.orderStatus] }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 订单来源 </template>

          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 付款状态</template>
            <span v-if="orderDetatilList.parkingOrderExit.extendInfo !== undefined">
              <span
                  v-if="orderDetatilList.parkingOrderExit.receivableAmount === 0"
              >无需付款</span
              >
              <span v-else>{{
                  orderDetatilList.isarray == 1 ? "已付款" : "未付款"
                }}</span>
            </span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单类型</template>
            <span> {{ orderType[orderDetatilList.extendInfo.orderType] }} </span>
            <!-- <span class="span-margin" @click="checkBusinessDetail"
              ><el-link type="primary" :underline="false">
                业务详情</el-link
              ></span -->
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单金额</template>
            <span v-if="orderDetatilList.parkingOrderExit.extendInfo !== undefined">{{
                parseFloat(
                    orderDetatilList.parkingOrderExit.receivableAmount
                ).toFixed(2)
              }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 交易金额（元）</template>
            <span v-if="orderDetatilList.parkingOrderExit.extendInfo !== undefined">{{
                parseFloat(
                    orderDetatilList.parkingOrderExit.transactionAmount
                ).toFixed(2)
              }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 线上缴费</template>
            <span v-if="orderDetatilList.parkingOrderExit.extendInfo !== undefined">{{
                parseFloat(
                    orderDetatilList.parkingOrderExit.transactionAmount
                ).toFixed(2)
              }}</span>
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 线下缴费 </template>

          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 优惠券抵扣</template>
            <span v-if="orderDetatilList.parkingOrderExit.extendInfo !== undefined">{{
                parseFloat(
                    orderDetatilList.parkingOrderExit.discountAmount
                ).toFixed(2)
              }}</span>
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 抵扣额 </template>

          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> 代金券抵扣 </template>
            {{orderDetatilList.discountAmount }}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> 岗亭减免 </template>

          </el-descriptions-item> -->

        </el-descriptions>
      </easy-card>

      <div class="separate-line"></div>

      <easy-card title="车场信息">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 城区</template>
            {{ orderDetatilList.regionName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 车场名称</template>
            {{ orderDetatilList.parklotName }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 岗亭值班人 </template>

          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 泊位号 </template>
            {{orderDetatilList.parkingSpaceId}}
          </el-descriptions-item> -->

          <el-descriptions-item>
            <template slot="label"> 驶入时间</template>
            {{ orderDetatilList.intoDateTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 驶出时间</template>
            <span v-if="orderDetatilList.parkingOrderExit.extendInfo !== undefined">{{
                orderDetatilList.parkingOrderExit.exitDateTime
              }}</span>
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>

      <div class="separate-line"></div>

      <easy-card title="执行信息">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 用户车牌</template>
            <Numplate :type="orderDetatilList.extendInfo.color">{{
                orderDetatilList.numplate
              }}
            </Numplate>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 手机号</template>
            {{ orderDetatilList.mobile }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 证件拍照</template>
            <span @click="checkIdPicture"><el-link :underline="false" type="primary">
                查看</el-link></span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 放行凭证</template>
            <span @click="checkReleasePicture"><el-link :underline="false" type="primary">
                查看</el-link></span>
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>

      <div class="separate-line"></div>

      <easy-card style="position: relative" title="计费信息">
        <el-button
            class="add-btn"
            size="mini"
            type="primary"
            @click="checkPriceRule"
        >查看计费规则
        </el-button
        >
        <div v-if="orderDetatilList.parkingOrderExit.extendInfo !== undefined" class="orderAmount">
          订单金额：<span style="color: #0768FD">{{
            orderDetatilList.parkingOrderExit.receivableAmount
          }}</span>
          元
        </div>
      </easy-card>

      <div class="separate-line"></div>
      <easy-card title="订单记录">
        <div>
          <p class="record-tilte">驶入记录</p>
          <el-table
              v-loading="isLoading"
              :data="driveInTableData"
              border
              class="common-table"
              size="medium"
              style="width: 100%"
          >
            <el-table-column label="操作模块" prop="leaseTitle">
            </el-table-column>
            <el-table-column label="记录类型" prop="parkingName">
            </el-table-column>
            <el-table-column label="操作原因"></el-table-column>
            <el-table-column
                label="操作详情"
                prop="longLeasePeriod"
                width="236"
            >
            </el-table-column>
            <el-table-column label="操作人类型" prop="buyWay">
            </el-table-column>
            <el-table-column
                label="操作人"
                prop="licensePlateColor"
            >
            </el-table-column>
            <el-table-column label="操作时间" prop="leasePrice">
            </el-table-column>
          </el-table>
        </div>

        <div>
          <p class="record-tilte">驶离记录</p>
          <el-table
              v-loading="isLoading"
              :data="driveOutTableData"
              border
              class="common-table"
              size="medium"
              style="width: 100%"
          >
            <el-table-column label="操作模块" prop="leaseTitle">
            </el-table-column>
            <el-table-column label="记录类型" prop="parkingName">
            </el-table-column>
            <el-table-column label="操作原因"></el-table-column>
            <el-table-column
                label="操作详情"
                prop="longLeasePeriod"
            >
            </el-table-column>
            <el-table-column label="操作人类型" prop="buyWay">
            </el-table-column>
            <el-table-column
                label="操作人"
                prop="licensePlateColor"
            >
            </el-table-column>
            <el-table-column label="操作时间" prop="leasePrice">
            </el-table-column>
          </el-table>
        </div>

        <div>
          <p class="record-tilte">支付记录</p>
          <el-table
              v-loading="isLoading"
              :data="payRecordTableData"
              border
              class="common-table"
              size="medium"
              style="width: 100%"
          >
            <el-table-column label="来源端" prop="leaseTitle">
            </el-table-column>
            <el-table-column label="付款方式" prop="parkingName">
            </el-table-column>
            <el-table-column label="交易金额"></el-table-column>
            <el-table-column
                label="交易时间"
                prop="longLeasePeriod"
            >
            </el-table-column>
            <el-table-column label="状态" prop="buyWay">
            </el-table-column>
          </el-table>
        </div>
      </easy-card>
    </div>

    <!-- 查看图片弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="pictureDialogFormVisible"
        class="common-dialog"
        title="停车图片"
    >
      <div class="showPicture">
        <div class="pictureBox boxF1">
          <div class="carNumber">
            <span>{{ orderDetatilList.numplate }}</span>
          </div>
          <div>
            <el-carousel class="photo-width" height="300px" trigger="click">
              <el-carousel-item>
                <el-image
                    :preview-src-list="driveInPhotoList"
                    :src="orderDetatilList.extendInfo.driveInPhoto"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="inOrOutTime"><span style="margin-left: 16px;">{{ orderDetatilList.intoDateTime }}</span></div>
        </div>
        <div class="pictureBox boxF2">
          <div class="carNumber"><span>{{ orderDetatilList.numplate }}</span></div>
          <div>
            <el-carousel class="photo-width" height="300px" trigger="click">
              <el-carousel-item>
                <el-image
                    :preview-src-list="driveOutPhotoList"
                    :src="orderDetatilList.parkingOrderExit.extendInfo.extendInfo !== undefined ? orderDetatilList.parkingOrderExit.extendInfo.driveOutPhoto : ''"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="inOrOutTime"><span style="margin-left: 16px;"><span
              v-if="orderDetatilList.parkingOrderExit.extendInfo !== undefined">
                {{ orderDetatilList.parkingOrderExit.exitDateTime }}
              </span></span></div>
        </div>
      </div>
      <div class="table-list">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 车牌号</template>
            <Numplate :type="orderDetatilList.extendInfo.color">{{
                orderDetatilList.numplate
              }}
            </Numplate>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 停车时长</template>
            {{ orderDetatilList.parkingTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单金额</template>
            <span v-if="orderDetatilList.parkingOrderExit.extendInfo !== undefined">{{
                parseFloat(
                    orderDetatilList.parkingOrderExit.receivableAmount
                ).toFixed(2)
              }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 实收（元）</template>
            <span v-if="orderDetatilList.parkingOrderExit.extendInfo !== undefined">{{
                parseFloat(
                    orderDetatilList.parkingOrderExit.transactionAmount
                ).toFixed(2)
              }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 是否付款</template>
            <span v-if="orderDetatilList.parkingOrderExit.extendInfo !== undefined">
              <span
                  v-if="orderDetatilList.parkingOrderExit.receivableAmount === 0"
              >无需付款</span
              >
              <span v-else>{{
                  orderDetatilList.isarray == 1 ? "已付款" : "未付款"
                }}</span>
            </span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 城区</template>
            {{ orderDetatilList.regionName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 车场</template>
            {{ orderDetatilList.parklotName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <!-- <template slot="label"> 泊位号 </template> -->
            <!-- {{orderDetatilList.parkingSpaceId}} -->
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 入场时间</template>
            {{ orderDetatil.parkingLotName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 出场时间</template>
            {{ orderDetatil.parkingSpaceCode }}
          </el-descriptions-item> -->
        </el-descriptions>
      </div>
      <div class="parkingDetail"></div>
    </el-dialog>

    <!-- 查看业务详情弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="businessDetailDialogFormVisible"
        class="common-dialog"
        title="业务详情"
    >
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
            align="center"
            fixed="left"
            label="业务类型"
            prop="orderCode"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="变更原因"
            prop="numplate"
        >
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 查看计费规则详情 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="detailDialogFormVisible"
        class="common-dialog"
        title="查看计费"
    >
      <div v-loading="isCheckRuleLoading">
        <div>
          <easy-card class="box" title="免费时长">
            <p v-if="freeTimeTable.length > 0" class="remarkTip">
              减免方式：{{
                freeTimeTable[0].reductionType === 0
                    ? "按订单时段减免"
                    : "按计费时段减免"
              }}/{{
                freeTimeTable[0].calcTimeFree === 0 ? "超时计入" : "超时不计入/"
              }}
              <span v-if="freeTimeTable[0].calcTimeFree === 1">{{
                  freeTimeTable.freeType === 0 ? "前免费" : "后免费"
                }}</span>
            </p>
            <el-table
                :data="freeTimeTable"
                border
                class="common-table"
                size="medium"
                style="width: 100%"
            >
              <el-table-column label="生效车牌" prop="plateType">
                <template slot-scope="scope">
                  <span>{{ formatNumplate(scope.row.plateType) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="生效周期" prop="activePeriod">
                <template slot-scope="scope">
                <span>{{
                    scope.row.activePeriod === "0"
                        ? "不限"
                        : scope.row.activePeriod === "1"
                            ? "工作日"
                            : "休息日"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="免费时长" prop="freeTime">
                <template slot-scope="scope">
                  {{ scope.row.freeTime }}分钟
                </template>
              </el-table-column>
            </el-table>
          </easy-card>
        </div>

        <div>
          <easy-card class="box" title="封顶金额">
            <p v-if="topRuleTable.length > 0" class="remarkTip">
              减免方式：{{
                topRuleTable.limitType === 0
                    ? "按自然日"
                    : topRuleTable.limitType === 1
                        ? "按24小时"
                        : "按整笔订单"
              }}
            </p>
            <el-table
                :data="topRuleTable"
                border
                class="common-table"
                size="medium"
                style="width: 100%"
            >
              <el-table-column label="生效车牌" prop="plateType">
                <template slot-scope="scope">
                  <span>{{ formatNumplate(scope.row.plateType) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="生效周期" prop="activePeriod">
                <template slot-scope="scope">
                  <span>{{ scope.row.activePeriod === 0 ? "不限" : "" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="时间段" prop="activeType">
                <template slot-scope="scope">
                  <span>{{ scope.row.activeType === 0 ? "全天" : "" }}</span>
                  <!-- <span v-if="scope.row.activeType === '1'">{{scope.row.activeTimeBegin}}~{{scope.row.activeTimeEnd}}</span> -->
                </template>
              </el-table-column>
              <el-table-column label="封顶金额(元)" prop="maxLimit">
              </el-table-column>
            </el-table>
          </easy-card>
        </div>

        <div>
          <easy-card class="box" title="计费规则">
            <el-table
                :data="priceRuleTable"
                border
                class="common-table"
                size="medium"
                style="width: 100%"
            >
              <el-table-column label="生效车牌" prop="plateType">
                <template slot-scope="scope">
                  <span>{{ formatNumplate(scope.row.plateType) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="生效周期" prop="activeTimeType">
                <template slot-scope="scope">
                  {{
                    scope.row.activeTimeType === 0
                        ? "不限"
                        : scope.row.activeTimeType === 1
                            ? "工作日"
                            : "休息日"
                  }}
                  <span v-if="scope.row.activeLegalHolidays === 0"
                  >(启用法定节假日)</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="时间段" prop="effectualTime">
                <template slot-scope="scope">
                  {{
                    scope.row.activeTimeScope === "00:00:00-23:59:59"
                        ? "全天"
                        : ""
                  }}
                  <span
                      v-if="scope.row.activeTimeScope !== '00:00:00-23:59:59'"
                  >{{ scope.row.activeTimeScope }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="计费方式" prop="contactNumber" width="250">
                <template slot-scope="scope">
                <span v-if="scope.row.ruleType === 0">
                  {{ scope.row.price }}元/{{
                    scope.row.calcUnit
                  }}分钟/不足单位时长{{
                    scope.row.notEnoughUnit === 0 ? "收费" : "免费"
                  }}/切换计费时段{{
                    scope.row.unitChange === 0
                        ? "开始新单位时长"
                        : "继续当前单位时长"
                  }}
                </span>
                  <span v-if="scope.row.ruleType === 1">
                  {{ scope.row.price }}元/次<span
                      v-if="scope.row.freeTime !== ''"
                  >,按次免费时长{{ scope.row.freeTime }}分钟</span
                  >
                </span>
                  <span v-if="scope.row.ruleType === 2">
                  {{ formatLadderList(scope.row.calcDetailList) }}
                  <div
                      v-for="item in scope.row.calcDetailList"
                      :key="item.text"
                  >
                    {{ item.text }}
                  </div>
                </span>
                </template>
              </el-table-column>
            </el-table>
          </easy-card>
        </div>

        <div>
          <easy-card class="box" title="预支付时长">
            <el-table
                :data="paymentTimeTable"
                border
                class="common-table"
                size="medium"
                style="width: 100%"
            >
              <el-table-column label="预支付时长(分钟)" prop="prePayTime">
                <template slot-scope="scope">
                  <span>{{ scope.row.prePayTime }}</span>
                </template>
              </el-table-column>
            </el-table>
          </easy-card>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDetail">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import Numplate from "@/components/Numplate";
import {getCalcDetailsByParkId} from "@/api/parkingOrder";

export default {
  name: 'parking_order_detail',
  data() {
    return {
      isLoading: false,
      dataList: [],
      ParkingLotId: '',
      orderDetatilList: {}, //从上个页面获取到的订单信息
      driveInPhotoList: [],
      driveOutPhotoList: [],
      pictureDialogFormVisible: false, //查看图片弹窗
      businessDetailDialogFormVisible: false, //查看业务详情弹窗
      driveInTableData: [], //驶入记录
      driveOutTableData: [], //驶离记录
      payRecordTableData: [], //支付记录
      orderType: {
        "0": "临停",
        "1": "白名单",
        "2": "黑名单",
        "3": "长租",
        "4": "预存",
        "5": "访客",
        "6": "共享"
      },
      orderStatus: {
        "0": "正常",
        "1": "无效",
        "2": "审核",
        "3": "复议",
        "": "正常",
      },
      //查看计费规则详情
      isCheckRuleLoading: false,
      freeTimeTable: [], //免费时长表格
      topRuleTable: [], //封顶金额表格
      priceRuleTable: [], //计费规则表格
      paymentTimeTable: [], //预支付时长表格
      detailDialogFormVisible: false, //查看计费规则详情弹出窗
    };
  },
  components: {
    Numplate,
  },
  methods: {
    //返回停车订单页面
    // goBack() {
    //   this.$router.back();
    // },
    //查看停车图片
    checkPicture() {
      this.pictureDialogFormVisible = true;
    },
    checkBusinessDetail() {
      this.businessDetailDialogFormVisible = true;
    },
    //查看证件图片
    checkIdPicture() {
      this.$message({
        message: '暂无图片信息',
        type: 'warning'
      });
    },
    //查看放行凭证
    checkReleasePicture() {
      this.$message({
        message: '暂无图片信息',
        type: 'warning'
      });
    },
    //查询订单计费规则
    getCalcDetailsByParkId() {
      const ParkingLotId = this.ParkingLotId;
      this.isCheckRuleLoading = true;
      getCalcDetailsByParkId(ParkingLotId).then((res) => {
        this.isCheckRuleLoading = false;
        if (res && res.code === 30 && res.result) {
          this.freeTimeTable = res.returnObject.calcFreeList;
          this.topRuleTable = res.returnObject.calcMaxLimitList;
          this.priceRuleTable = res.returnObject.calcList;
          this.paymentTimeTable.push(res.returnObject.calcPrepay);
        }
      });
    },
    //查看计费规则弹窗
    checkPriceRule() {
      this.detailDialogFormVisible = true;
      this.getCalcDetailsByParkId();
    },
    //处理车牌
    formatNumplate(items) {
      items = typeof items === "string" ? items.split(",") : items;
      return items
          .map((item) => {
            const map = {
              0: "通用",
              "01": "黄牌",
              "02": "蓝牌",
              "06": "黑牌",
              "03": "新能源",
            };
            return map[item];
          })
          .join(",");
    },
    //处理阶梯计费
    formatLadderList(items) {
      items.map((item) => {
        item.text = `${item.calcStart}~${item.calcEnd}分钟, ${item.price}元/${item.calcUnit}分钟`;
        if (item.calcEnd === 99999) {
          item.text = `剩余时长，${item.price}元/${item.calcUnit}分钟`;
        }
      });
    },
    //关闭查看计费窗口
    closeDetail() {
      this.detailDialogFormVisible = false;
    },
  },
  mounted() {
    const result = JSON.parse(sessionStorage.getItem("order_detail"));
    this.orderDetatilList = result;
    this.ParkingLotId = result.parkingLotInfoId;
    this.driveInPhotoList.push(result.extendInfo.driveInPhoto);
    if (result.parkingOrderExit.extendInfo !== undefined) {
      this.driveOutPhotoList.push(
          result.parkingOrderExit.extendInfo.driveOutPhoto
      );
    }
  },
};
</script>

<style lang="less" scoped>
.record-tilte {
  margin: 14px 0;
}

.span-margin {
  margin-left: 4px;
}

.showPicture {
  display: flex;
  flex-direction: row;
}

.pictureBox {
  border: 1px solid #bbb;
  width: 48%;
}

.boxF1 {
  margin-right: 10px;
}

.boxF2 {
  margin-left: 10px;
}

.carNumber {
  width: 100%;
  line-height: 30px;
  text-align: center;
  background: #0768FD;
  color: #fff;
}

.inOrOutTime {
  background: #f0f1f5;
  width: 100%;
  display: inline-block;
  line-height: 30px;
}

.photo-width {
  margin: 12px 8px;
}

.table-list {
  margin-top: 20px;
}

.orderAmount {
  font-size: 14px;
  font-weight: 600;
}

.add-btn {
  background: #0768FD;
  border-radius: 3px;
  border-color: #0768FD;
  font-size: 12px;
  position: absolute;
  top: 6px;
  right: 10px;
}
</style>
