<template>
  <div>
    <el-badge v-if="badgeVal!==''" class="margin-top10 margin-right10" :value="badgeVal">
      <div :class="className" class="numplate">
        <slot></slot>
      </div>
    </el-badge>
    <div v-else :class="className" class="numplate">
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  name: "numplate",
  props: {
    type: {
      type: String,
      default: "02",
    },
    badgeVal:{
      type:String,
      default:"",
    }
  },
  computed: {
    className() {
      const type = this.type;
      if (!type || type === '' || type === '99' || type === '66') {
        return 'type_99';
      }
      if (type === '蓝色' || type === '02' || type == "0") {
        return 'type_02'
      }
      if (type === '黄色' || type === '01') {
        return 'type_01'
      }
      if (type === '绿色' || type === '03') {
        return 'type_03'
      }
      if (type === '白色' || type === '23') {
        return 'type_23'
      }
      if (type === '黑色' || type === '06') {
        return 'type_06'
      }
      return `type_${this.type}`;
    },
  },
};
</script>

<style lang="less" scoped>
.numplate {
  border: 1px solid #ddd;
  display: inline-block;
  padding: 3px 10px;
  border-radius: 4px;
  color: #fff;
  font-family: "黑体";

  &.type_02 {
    background: #3075FA;
  }

  &.type_01 {
    background: #e6b201;
  }

  &.type_03 {
    background: #0bc029;
  }

  &.type_04 {
    background: linear-gradient(90deg, #e6b201 42.8%, green 0, green);
  }

  &.type_06 {
    background: #333;
  }

  &.type_23 {
    background: #fff;
    color: #333;
  }

  &.type_99 {
    // background: #bbbbbb;
    // color: #333;
    background: #0bc029;
  }
}

/deep/ .el-badge__content {
  background-color: #0768FD;
}

/deep/ .el-badge__content.is-fixed {
  top: 0 !important;
  right: 20px !important;
}
</style>
